import { Order } from "./order"

export type Payment = {
  id: number
  sbpayment_order_id: string
  user_name: string
  order_id: number
  ticket_number: string
  amount: number
  pay_method: string
  status: string
  request_date: string
  res_date: string
  res_tracking_id: string
  memo?: string
  is_deleted_user: boolean
  order: Order
}

interface Json {
  [key: string]: string
}

export const paymentStatus: Json = {
  NOT_PURCHASED: '未払い',
  OK: 'クレジット支払い済み',
  NG: '支払い処理NG',
  CANCEL: '支払い処理キャンセル',
  PAID_CANCEL: '支払い済みキャンセル',
  REFUND: '払い戻し'
}

export const payMethod: Json = {
  credit: 'クレジット'
}

export const paidStatusList = ['OK', 'PAID_CANCEL', 'REFUND']

export const getPaymentEnabled = ($axios: any) => {
  return $axios.$get(`/payments/enabled`)
}

export const getPaymentStatus = ($axios: any, orderId: number) => {
  return $axios.$get(`/payments/status?order_id=${orderId}`)
}

export const getPayments = ($axios: any, param: any): Promise<Payment[]> => {
  const params = new URLSearchParams(param)
  return $axios.$get(`/payments/?${params.toString()}`)
}

export const paymentRefund = ($axios: any, paymentId: number) => {
  const params = new URLSearchParams({ id: paymentId } as any)
  return $axios.$post(`/payments/refund`, params)
}

export const patchPayment = ($axios: any, paymentId: number, data: any) => {
  return $axios.$patch(`/payments/${paymentId}`, data)
}
