import { getPaymentEnabled } from '~/models/payment'

export default async (context: any) => {
  if (context.$auth.loggedIn && context.store.state.paymentEnabled === undefined) {
    const res = await getPaymentEnabled(context.$axios)
    await context.store.commit(`updatePaymentEnabled`, {
      paymentEnabled: res.result
    })
  }
}
